import SwitchImage from '../hooks/useImage'
import mobileImage from '../assets/images/aboutMobile.jpg'
import desktopImage from '../assets/images/aboutDesktop.jpg'
import { useTranslation } from 'react-i18next'

export default function AboutBanner({ id }) {
  const { t } = useTranslation()
  return (
    <article
      id={id}
      className='flex justify-center py-16 sm:py-24'
    >
      <section className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col md:flex-row gap-8 2xl:gap-16'>
        <div className='w-full xl:w-3/5 2xl:w-2/3'>
          <div className='w-full lg:mb-0'>
            <h1 className='sm:text-5xl text-2xl  drop-shadow-md font-semibold sm:mb-2 text-gray-700'>
              {t('aboutSection.aboutTitle')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full'></div>
          </div>
          <h1 className='mt-2 sm:mt-4 sm:text-xl text-lg  drop-shadow-md font-normal text-gray-600'>
            {t('aboutSection.aboutContent')}
          </h1>
        </div>
        <div className='hidden xl:block w-2/5 2xl:w-1/3 shadow-md'>
          <SwitchImage
            mobile={mobileImage}
            desktop={desktopImage}
          />
        </div>
      </section>
    </article>
  )
}
