import SwitchVideo from '../hooks/useVideo'
import mobileVideo from '../assets/videos/mobileDealWhite.mp4'
import desktopVideo from '../assets/videos/desktopDealWhite.mp4'
import { useTranslation } from 'react-i18next'

export default function DealBannerWhite() {
  const { t } = useTranslation()
  return (
    <section className='flex justify-center py-12 sm:py-24'>
      <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col md:flex-row gap-8 2xl:gap-16'>
        <div className='w-full xl:w-3/5 2xl:w-3/5'>
          <div className='w-full lg:mb-0'>
            <h1 className='sm:text-5xl text-2xl  drop-shadow-md font-semibold sm:mb-2 text-gray-700'>
              {t('dealSectionWhite.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full'></div>
          </div>
          <h1 className='mt-2 sm:mt-4 text-lg sm:text-xl text-gray-600 drop-shadow-md font-normal '>
            {t('dealSectionWhite.content')}
          </h1>
        </div>
        <div className='hidden xl:block w-2/3 2xl:w-2/5 shadow-md'>
          <SwitchVideo
            mobile={mobileVideo}
            desktop={desktopVideo}
          />
        </div>
      </div>
    </section>
  )
}
