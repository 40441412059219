import TopPropertiesGridTwo from '../components/TopPropertiesGridTwo'
import Video from '../components/Video'
import mobile from '../assets/videos/topMobile.mp4'
import desktop from '../assets/videos/topPropertyDesktop.mp4'
import { useTranslation } from 'react-i18next'

export default function TopProperties() {
  const { t } = useTranslation()
  return (
    <article>
      <section>
        <Video
          mobile={mobile}
          desktop={desktop}
          title={t('topOffersVideo.title')}
          subTitle={t('topOffersVideo.subTitle')}
          link={'#topPropertiesGrid'}
        />
      </section>
      <TopPropertiesGridTwo id='topPropertiesGrid' />
    </article>
  )
}
