import { Link } from 'react-router-dom'
import { motion, useCycle, AnimatePresence } from 'framer-motion'
import logo from '../assets/images/logoOlga.jpg'
import { useTranslation } from 'react-i18next'

export default function Navbar() {
  const [t, i18n] = useTranslation()
  const changeLang = (lang) => {
    i18n.changeLanguage(lang)
  }

  const navlinks = [
    { title: t('navLink.about'), href: '/' },
    { title: t('navLink.properties'), href: '/properties' },
    { title: t('navLink.services'), href: '/services' },
    { title: t('navLink.topOffers'), href: '/topProperties' },
    { title: t('navLink.news'), href: '/news' },
    { title: t('navLink.contacts'), href: '/contact' },
  ]

  const [mobileNav, toggleMobileNav] = useCycle(false, true)
  return (
    <header className='fixed top-0 z-50 sm:bg-white/20 sm:backdrop-blur-md w-full sm:shadow-md sm:px-4'>
      <div className='px-2 md:px-4'>
        <div className='flex flex-row items-center align-middle justify-between py-1'>
          <div className='flex items-center gap-6'>
            <Link
              to='/'
              className='relative'
            >
              <img
                src={logo}
                alt='logo'
                className='w-11 sm:w-16 h-11 sm:h-16 shadow-lg sm:shadow-none rounded-full sm:rounded-none'
              />
            </Link>
            <nav className='hidden sm:flex items-center gap-4 font-bold text-center'>
              {navlinks.map(({ href, title }) => {
                return (
                  <Link
                    key={title}
                    to={href}
                    className='hover:text-gray-400 transition-all duration-300'
                  >
                    {title}
                  </Link>
                )
              })}
            </nav>
          </div>
          <div className='relative z-50 sm:bg-transparent bg-white/30 backdrop-blur-md rounded-full shadow-lg sm:shadow-none px-3 py-[15px] sm:p-0'>
            <motion.button
              animate={mobileNav ? 'open' : 'closed'}
              onClick={() => toggleMobileNav()}
              className='flex flex-col space-y-1'
            >
              <motion.span
                variants={{
                  closed: { rotate: 0, y: 0 },
                  open: { rotate: 45, y: 6 },
                }}
                className='w-5 h-[2px] rounded-full bg-black block'
              />
              <motion.span
                variants={{
                  closed: { opacity: 1 },
                  open: { opacity: 0 },
                }}
                className='w-5 h-[2px] rounded-full bg-black block'
              />
              <motion.span
                variants={{
                  closed: { rotate: 0, y: 0 },
                  open: { rotate: -45, y: -6 },
                }}
                className='w-5 h-[2px] rounded-full bg-black block'
              />
            </motion.button>
          </div>
          <AnimatePresence>
            {mobileNav && (
              <motion.div
                key='mobile-nav'
                variants={{
                  open: { x: '0%' },
                  closed: { x: '100%' },
                }}
                initial='closed'
                animate='open'
                exit='closed'
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 40 },
                  opacity: { duration: 0.2 },
                }}
                className='fixed right-0 top-0 w-3/4 md:w-1/2 lg:w-1/3 bg-white z-40'
              >
                <nav
                  onClick={() => toggleMobileNav()}
                  className='flex flex-col justify-center items-start gap-4 h-screen w-full text-black font-bold text-2xl sm:text-4xl pl-4 sm:pl-12'
                >
                  <div className='flex items-center gap-2 text-sm'>
                    <button
                      className='bg-gray-200 p-2 rounded-full hover:bg-gray-500'
                      onClick={() => changeLang('en')}
                    >
                      EN
                    </button>
                    <span>/</span>
                    <button
                      className='bg-gray-200 p-2 rounded-full hover:bg-gray-500'
                      onClick={() => changeLang('ru')}
                    >
                      RU
                    </button>
                  </div>
                  {navlinks.map(({ href, title }) => {
                    return (
                      <Link
                        key={title}
                        to={href}
                        className='hover:text-gray-400 transition-all duration-300'
                      >
                        {title}
                      </Link>
                    )
                  })}
                </nav>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </header>
  )
}
