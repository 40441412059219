import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import resources from '../utils/translations'

// const resources = {
//   en: {
//     translation: {
//       aboutTitle: 'About Our Company',
//       aboutContent: 'English content',
//     },
//   },
//   ru: {
//     translation: {
//       aboutTitle: 'О нашей компании',
//       aboutContent: 'Русский контент',
//     },
//   },
// }

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
