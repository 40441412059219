import { IoLogoWhatsapp } from 'react-icons/io'
import { BsTelegram } from 'react-icons/bs'
import { RiInstagramFill } from 'react-icons/ri'
import { FaFacebook, FaFacebookMessenger } from 'react-icons/fa6'
import { MdEmail, MdLocalPhone } from 'react-icons/md'
import { Link } from 'react-router-dom'
import logo from '../assets/images/logoOlga.jpg'
import { useTranslation } from 'react-i18next'

export default function Footer() {
  const { t } = useTranslation()
  return (
    <footer className='bg-black flex flex-col items-center py-4 sm:py-12 md:py-24'>
      <div className='w-full sm:w-5/6 px-2 sm:px-0'>
        <div className='w-full text-white flex flex-col xl:flex-row items-start'>
          <div className='w-full xl:w-1/2'>
            <h1 className='sm:text-5xl text-3xl drop-shadow-md font-semibold sm:mb-2'>
              {t('footer.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r from-red-500 to-purple-500 rounded-full'></div>
          </div>
          <h1 className='w-full xl:w-1/2 text-xl drop-shadow-md font-normal pt-2 xl:pt-0'>
            {t('footer.content')}
          </h1>
        </div>
        <div className='w-full flex items-center justify-between pt-24 pb-4 border-b-[1px] border-zinc-800 text-white'>
          <img
            src={logo}
            alt='logo'
            className='w-12 sm:w-16 h-12 sm:h-16'
          />
          <div className='flex items-center gap-4'>
            <h1 className='font-bold hidden sm:block'>Follow</h1>
            <Link
              to={''}
              className='tranition-all duration-300 hover:text-zinc-500'
            >
              <IoLogoWhatsapp size={28} />
            </Link>
            <Link
              to={''}
              className='tranition-all duration-300 hover:text-zinc-500'
            >
              <BsTelegram size={24} />
            </Link>
            <Link
              to={''}
              className='tranition-all duration-300 hover:text-zinc-500'
            >
              <RiInstagramFill size={28} />
            </Link>
            <Link
              to={''}
              className='tranition-all duration-300 hover:text-zinc-500'
            >
              <FaFacebook size={24} />
            </Link>
            <Link
              to={''}
              className='tranition-all duration-300 hover:text-zinc-500'
            >
              <FaFacebookMessenger size={24} />
            </Link>
          </div>
        </div>
        <div className='w-full flex justify-end pt-4 text-white'>
          <div className='flex items-center gap-4'>
            <div className='flex items-center gap-2'>
              <MdLocalPhone />
              <p>010123456789</p>
            </div>
            <div className='flex items-center gap-2'>
              <MdEmail />
              <h1>some@gmail.com</h1>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
