import ServicesHero from '../components/ServicesHero'
import Video from '../components/Video'
import mobile from '../assets/videos/servicesMob.mp4'
import desktop from '../assets/videos/servicesDesk.mp4'
import { useTranslation } from 'react-i18next'

export default function Services() {
  const { t } = useTranslation()
  return (
    <article>
      <section>
        <Video
          mobile={mobile}
          desktop={desktop}
          title={t('servicesVideo.title')}
          subTitle={t('servicesVideo.subTitle')}
          link={'#services'}
        />
      </section>
      <ServicesHero id={'services'} />
    </article>
  )
}
