import { createClient } from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'

const client = createClient({
  projectId: 'nia8ztqy',
  dataset: 'production',
  apiVersion: '2022-03-07',
  useCdn: true,
})

export default client

const builder = imageUrlBuilder(client)

export const urlFor = (source) => builder.image(source)
