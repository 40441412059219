import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import client from '../client'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Thumbs, Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/thumbs'
import 'swiper/css/navigation'
import { PortableText } from '@portabletext/react'
import { IoIosArrowBack } from 'react-icons/io'
import { IoBedOutline, IoResizeSharp, IoLogoWhatsapp } from 'react-icons/io5'
import { LuBath, LuBuilding2 } from 'react-icons/lu'
import { MdLocalPhone } from 'react-icons/md'
import { FaTelegramPlane } from 'react-icons/fa'

export default function Property() {
  const [singleProperty, setSingleProperty] = useState(null)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const { slug } = useParams()

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == '${slug}']{
           mainTitle,
           _id,
           slug,
           mainContent,
           propertyPrice,
           propertyType,
           shortDescription,
           agentName,
           agentAvatar{asset->{_id,url}},
           beds,
           baths,
           area,
           relatedImages[]->{
           _id,
           slug,
           thumbImage{asset->{_id,url}},
           desktopImage{asset->{_id,url}},
           mobileImage{asset->{_id,url}},
           }
      }`
      )
      .then((data) => setSingleProperty(data[0]))
      .catch(console.error)
  }, [slug])

  if (!singleProperty) return <div>loading...</div>

  return (
    <section className='relative w-full h-full flex flex-col items-center pt-12'>
      <Link
        to={'/properties'}
        className='fixed z-50 top-32 -left-1 text-neutral-300 bg-black/40 transition-all duration-200 hover:bg-black py-12 cursor-pointer rounded-tr-md rounded-br-md'
      >
        <IoIosArrowBack size={32} />
      </Link>
      <div className='w-full 2xl:w-5/6 px-2'>
        <div className='pt-2 md:pt-12 flex items-center'>
          <h1 className='font-bold text-3xl md:text-5xl'>
            {singleProperty.mainTitle}
          </h1>
        </div>
        <div className='w-full flex xl:flex-row flex-col gap-4'>
          <div className='w-full xl:w-2/3 relative'>
            <Swiper
              loop={true}
              spaceBetween={1}
              navigation={true}
              autoplay={{
                delay: 9000,
                disableOnInteraction: false,
              }}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              modules={[FreeMode, Thumbs, Navigation, Autoplay]}
              className='w-full h-full bg-black'
            >
              {singleProperty.relatedImages?.map((image, idx) => (
                <SwiperSlide key={idx}>
                  <img
                    alt={idx}
                    key={idx}
                    src={image.desktopImage?.asset.url}
                    className='w-full h-[700px] object-cover'
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className='absolute bottom-1 sm:bottom-4 left-1 sm:left-2 right-1 sm:right-2'>
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={1}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Thumbs]}
                className='thumbs bg-white/30 backdrop-blur-md cursor-pointer'
              >
                {singleProperty.relatedImages?.map((thumb, idx) => (
                  <SwiperSlide key={idx}>
                    <div className='w-full h-24 sm:h-36 p-1 sm:p-2 relative'>
                      <img
                        className='object-cover h-full w-full'
                        src={thumb.thumbImage?.asset.url}
                        alt='thumb_img'
                        fetchPriority='high'
                        loading='eager'
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className='w-full h-[500px] xl:h-[700px] xl:w-1/3'>
            <iframe
              width='100%'
              height='100%'
              title='map'
              scrolling='no'
              src='https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14198.592972860171!2d33.829953!3d27.1673556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2seg!4v1702477901835!5m2!1sru!2seg'
            ></iframe>
          </div>
        </div>
        <div className='w-full flex xl:flex-row flex-col'>
          <div className='w-full xl:w-2/3'>
            <div className='w-full py-12 flex xl:flex-row flex-col gap-2 xl:gap-0'>
              <div className='w-1/2 xl:border-r-[1px]'>
                <h1 className='text-5xl font-bold'>
                  {singleProperty.propertyPrice}
                  <span className='ml-4'>EGP</span>
                </h1>
              </div>
              <div className='w-1/2 flex items-center justify-start gap-6 px-2'>
                <div className='flex items-center gap-2'>
                  <IoBedOutline
                    size={32}
                    className='text-neutral-700'
                  />
                  <h1 className='font-bold'>{singleProperty.beds}</h1>
                </div>
                <div className='flex items-center gap-2'>
                  <LuBath
                    size={28}
                    className='text-neutral-700'
                  />
                  <h1 className='font-bold'>{singleProperty.baths}</h1>
                </div>
                <div className='flex items-center gap-2'>
                  <IoResizeSharp
                    size={28}
                    className='text-neutral-700'
                  />
                  <h1 className='font-bold'>
                    {singleProperty.area}
                    <span className='ml-2'>sq.m.</span>
                  </h1>
                </div>
              </div>
            </div>
            <div className='w-full py-4 border-t-[1px] border-b-[1px] mb-12 flex flex-col gap-4'>
              <h1 className='text-2xl text-zinc-500'>
                {singleProperty.shortDescription}
              </h1>
              <h1 className='text-5xl'>{singleProperty.mainTitle}</h1>
              <div className='flex'>
                <div className='flex items-center gap-2'>
                  <LuBuilding2
                    size={28}
                    className='text-neutral-700'
                  />
                  <h1 className='font-bold text-2xl'>
                    {singleProperty.propertyType}
                  </h1>
                </div>
              </div>
              <h1 className='text-xl'>
                <PortableText value={singleProperty.mainContent} />
              </h1>
            </div>
          </div>
          <div className='w-full md:w-2/3 lg:w-1/3 lg:ml-12 mb-6 lg:my-12'>
            <div className='rounded-lg border-[1px] shadow-md flex flex-col justify-center items-start p-4'>
              <div className='w-full flex-col border-b-[1px] pb-8'>
                <h1 className='flex items-center gap-2 pb-4'>
                  <span>
                    <MdLocalPhone size={32} />
                  </span>
                  Phone Number here
                </h1>
                <div className='w-full flex gap-4'>
                  <Link
                    to={''}
                    className='px-12 py-4 bg-green-500 rounded-lg shadow-sm hover:shadow-lg cursor-pointer transition-all duration-300'
                  >
                    <IoLogoWhatsapp
                      size={22}
                      className='text-white'
                    />
                  </Link>
                  <Link
                    to={''}
                    className='px-12 py-4 bg-sky-500 rounded-lg shadow-sm hover:shadow-lg cursor-pointer transition-all duration-300'
                  >
                    <FaTelegramPlane
                      size={22}
                      className='text-white'
                    />
                  </Link>
                </div>
              </div>
              <div className='flex items-center gap-4 pt-8'>
                <img
                  alt='avatar_agent'
                  src={singleProperty.agentAvatar?.asset.url}
                  className='w-16 h-16 rounded-full border-2 border-black'
                />
                <h1 className='font-bold'>{singleProperty.agentName}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
