import SwitchImage from '../hooks/useImage'
import reserveMobile from '../assets/images/reserveMob.jpg'
import reserveDesktop from '../assets/images/reserveDesk.jpg'
import watchMobile from '../assets/images/watchMob.jpg'
import watchDesktop from '../assets/images/watchDesk.jpg'
import lawMobile from '../assets/images/lawMob.jpg'
import lawDesktop from '../assets/images/lawDesk.jpg'
import finishingMobile from '../assets/images/finishMob.jpg'
import finishingDesktop from '../assets/images/finishDesk.jpg'
import constructionMobile from '../assets/images/constructionMob.jpg'
import constructionDesktop from '../assets/images/constructionDesk.jpg'
import maintainMobile from '../assets/images/maintainMob.jpg'
import maintainDesktop from '../assets/images/maintainDesk.jpg'
import SwitchVideo from '../hooks/useVideo'
import mobileVideo from '../assets/videos/choosingMobile.mp4'
import desktopVideo from '../assets/videos/choosingDesktop.mp4'
import { useTranslation } from 'react-i18next'

export default function ServicesHero({ id }) {
  const { t } = useTranslation()
  return (
    <section
      id={id}
      className='flex flex-col items-center justify-center'
    >
      <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col xl:flex-row items-center gap-8 2xl:gap-16 py-12 sm:py-24'>
        <div className='w-full xl:w-3/5 2xl:w-2/3'>
          <div className='w-full'>
            <h1 className='sm:text-5xl text-2xl font-bold drop-shadow-md pb-2 text-gray-700'>
              {t('services.selection.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full'></div>
          </div>
          <h1 className='pt-2 text-md sm:text-xl drop-shadow-md font-normal text-gray-600'>
            {t('services.selection.content')}
          </h1>
        </div>
        <div className='w-full 2xl:w-1/3 shadow-md'>
          <SwitchVideo
            mobile={mobileVideo}
            desktop={desktopVideo}
          />
        </div>
      </div>
      <div className='py-12 md:py-24 w-full flex flex-col items-center bg-black'>
        <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col xl:flex-row items-center gap-8 2xl:gap-16 text-white'>
          <div className='w-full 2xl:w-1/3 shadow-md'>
            <SwitchImage
              mobile={reserveMobile}
              desktop={reserveDesktop}
            />
          </div>
          <div className='w-full xl:w-3/5 2xl:w-2/3'>
            <div className='w-full'>
              <h1 className='sm:text-5xl text-2xl drop-shadow-md font-bold pb-2'>
                {t('services.reservation.title')}
              </h1>
              <div className='h-1 w-20 bg-gradient-to-r  from-green-500 to-blue-600 rounded-full'></div>
            </div>
            <h1 className='pt-2 text-md sm:text-xl drop-shadow-md font-normal'>
              {t('services.reservation.content')}
            </h1>
          </div>
        </div>
      </div>
      <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col xl:flex-row items-center gap-8 2xl:gap-16 pb-12 pt-4 sm:py-24'>
        <div className='w-full xl:w-3/5 2xl:w-2/3'>
          <div className='w-full lg:mb-0'>
            <h1 className='sm:text-5xl text-2xl drop-shadow-md font-bold pb-2'>
              {t('services.study.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full'></div>
          </div>
          <h1 className='pt-2 text-md sm:text-xl text-gray-600 drop-shadow-md font-normal'>
            {t('services.study.content')}
          </h1>
        </div>
        <div className='w-full 2xl:w-1/3 shadow-md'>
          <SwitchImage
            mobile={watchMobile}
            desktop={watchDesktop}
          />
        </div>
      </div>
      <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col items-center xl:flex-row gap-8 2xl:gap-16'>
        <div className='hidden xl:block w-full 2xl:w-1/3 shadow-md'>
          <img
            src={lawDesktop}
            className='object-cover w-full h-full bg-black'
            alt='law_img'
          />
        </div>
        <div className='w-full xl:w-3/5 2xl:w-2/3'>
          <div className='w-full'>
            <h1 className='sm:text-5xl text-2xl  drop-shadow-md font-semibold pb-2'>
              {t('services.legal.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r  from-green-500 to-blue-600 rounded-full'></div>
          </div>
          <h1 className='pt-2 text-md sm:text-xl drop-shadow-md font-normal'>
            {t('services.legal.content')}
          </h1>
        </div>
        <div className='xl:hidden w-full 2xl:w-1/3 shadow-md'>
          <img
            src={lawMobile}
            className='object-cover w-full h-full bg-black'
            alt='law_img'
          />
        </div>
      </div>
      <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col items-center xl:flex-row gap-8 2xl:gap-16 py-12 sm:py-24'>
        <div className='w-full xl:w-3/5 2xl:w-2/3'>
          <div className='w-full lg:mb-0'>
            <h1 className='sm:text-5xl text-2xl  drop-shadow-md font-semibold pb-2'>
              {t('services.repair.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full'></div>
          </div>
          <h1 className='pt-2 text-md sm:text-xl drop-shadow-md font-normal'>
            {t('services.repair.content')}
          </h1>
        </div>
        <div className='w-full 2xl:w-1/3 shadow-md'>
          <SwitchImage
            mobile={finishingMobile}
            desktop={finishingDesktop}
          />
        </div>
      </div>
      <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col items-center xl:flex-row gap-8 2xl:gap-16'>
        <div className='hidden xl:block w-full 2xl:w-1/3 shadow-md'>
          <img
            src={constructionDesktop}
            className='object-cover w-full h-full bg-black'
            alt='law_img'
          />
        </div>
        <div className='w-full xl:w-3/5 2xl:w-2/3'>
          <div className='w-full'>
            <h1 className='sm:text-5xl text-2xl drop-shadow-md font-semibold pb-2 '>
              {t('services.construction.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r  from-green-500 to-blue-600 rounded-full'></div>
          </div>
          <h1 className='pt-2 text-md sm:text-xl drop-shadow-md font-normal'>
            {t('services.construction.content')}
          </h1>
        </div>
        <div className='xl:hidden w-full 2xl:w-1/3 shadow-md'>
          <img
            src={constructionMobile}
            className='object-cover w-full h-full bg-black'
            alt='law_img'
          />
        </div>
      </div>
      <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col items-center xl:flex-row gap-8 2xl:gap-16 py-12 sm:py-24'>
        <div className='w-full xl:w-3/5 2xl:w-2/3'>
          <div className='w-full'>
            <h1 className='sm:text-5xl text-2xl  drop-shadow-md font-semibold pb-2'>
              {t('services.afterSale.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full'></div>
          </div>
          <h1 className='pt-2 text-md sm:text-xl drop-shadow-md font-normal'>
            {t('services.afterSale.content')}
          </h1>
        </div>
        <div className='w-full 2xl:w-1/3 shadow-md'>
          <SwitchImage
            mobile={maintainMobile}
            desktop={maintainDesktop}
          />
        </div>
      </div>
    </section>
  )
}
