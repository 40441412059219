import ContactForm from '../components/ContactForm'

export default function Contact() {
  return (
    <section className='w-full h-screen sm:h-[600px] flex flex-col justify-center items-center'>
      <div className='w-full h-full relative'>
        <iframe
          width='100%'
          height='100%'
          title='map'
          scrolling='no'
          src='https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14198.592972860171!2d33.829953!3d27.1673556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sru!2seg!4v1702477901835!5m2!1sru!2seg'
        ></iframe>
        <div className='absolute right-14 sm:right-24 bottom-4 sm:bottom-24 w-3/4 sm:w-1/2 xl:w-1/3 2xl:w-1/4'>
          <ContactForm />
        </div>
      </div>
    </section>
  )
}
