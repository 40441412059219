import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import client from '../client'
import imageUrlBuilder from '@sanity/image-url'
import { PortableText } from '@portabletext/react'
import SwitchImage from '../hooks/useImage'
import { IoIosArrowBack } from 'react-icons/io'

const builder = imageUrlBuilder(client)
function urlFor(source) {
  return builder.image(source)
}

export default function Blog() {
  const [singleBlogPost, setSingleBlogPost] = useState(null)
  const { slug } = useParams()

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == '${slug}']{
          blogTitle,
          _id,
          slug,
          authorAvatar{asset->{_id,url}},
          authorName,
          firstDesktopImage{asset->{_id,url}},
          firstMobileImage{asset->{_id,url}},
          mainContentFirst,
          secondDesktopImage{asset->{_id,url}},
          secondMobileImage{asset->{_id,url}},
          mainContentSecond
        }`
      )
      .then((data) => setSingleBlogPost(data[0]))
      .catch(console.error)
  }, [slug])

  if (!singleBlogPost) return <div className='text-6xl'>loading...</div>

  return (
    <section className='min-h-screen flex flex-col items-center pt-14 sm:pt-24'>
      <Link
        to={'/news'}
        className='fixed z-50 top-1/2 -left-1 text-white bg-black/40 transition-all duration-200 hover:bg-black py-12 cursor-pointer rounded-tr-md rounded-br-md'
      >
        <IoIosArrowBack size={32} />
      </Link>
      <div className='w-full 2xl:w-5/6 px-2 lg:px-12 2xl:px-0 flex flex-col justify-start items-start lg:items-start gap-2 pb-6'>
        <h1 className='text-3xl font-bold md:text-5xl drop-shadow-sm'>
          {singleBlogPost.blogTitle}
        </h1>
        <div className='w-full sm:h-[500px] lg:h-[700px]'>
          <SwitchImage
            desktop={urlFor(singleBlogPost.firstDesktopImage).url()}
            mobile={urlFor(singleBlogPost.firstMobileImage).url()}
          />
        </div>
        <div className='text-md sm:text-2xl drop-shadow-sm py-0 lg:py-12 lg:px-24 2xl:px-48'>
          <PortableText value={singleBlogPost.mainContentFirst} />
        </div>
        <div className='w-full sm:h-[500px] lg:h-[700px]'>
          <SwitchImage
            desktop={urlFor(singleBlogPost.secondDesktopImage).url()}
            mobile={urlFor(singleBlogPost.secondMobileImage).url()}
          />
        </div>
        <div className='text-md sm:text-2xl drop-shadow-sm  py-0 lg:py-12 lg:px-24 2xl:px-48'>
          <PortableText value={singleBlogPost.mainContentSecond} />
        </div>
        <div className='flex items-center justify-between w-full'>
          <div className='flex items-center gap-2 sm:gap-4'>
            <img
              src={urlFor(singleBlogPost.authorAvatar).url()}
              alt='author_img'
              className='w-16 h-16 rounded-full border-[1px] object-cover shadow-lg'
            />
            <div>
              <p className='text-sm'>Post By</p>
              <h1 className='font-bold text-md drop-shadow-md'>
                {singleBlogPost.authorName}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
