import { Link } from 'react-router-dom'
import SwitchImage from '../hooks/useImage'

export default function NewsCard({
  link,
  desktopImage,
  mobileImage,
  title,
  description,
  avatar,
  author,
}) {
  return (
    <Link
      to={link}
      className='flex flex-col items-start justify-start shadow-lg shadow-gray-300 h-[400px] md:h-[640px] w-full hover:opacity-80 transition-all duration-300'
    >
      <div className='w-full h-3/5 md:h-3/4'>
        <SwitchImage
          desktop={desktopImage}
          mobile={mobileImage}
        />
      </div>
      <div className='p-2 w-full h-2/5 md:h-1/4 flex flex-col justify-center sm:gap-2 gap-1'>
        <h1 className='font-bold text-xl sm:text-2xl drop-shadow-md'>
          {title}
        </h1>
        <p className='drop-shadow-md'>{description}</p>
        <div className='flex items-center gap-4'>
          <img
            src={avatar}
            alt='author_img'
            className='w-12 sm:w-16 h-12 sm:h-16 rounded-full border-[1px] object-cover shadow-lg'
          />
          <h1 className='font-bold drop-shadow-md'>{author}</h1>
        </div>
      </div>
    </Link>
  )
}
