import { SlLocationPin } from 'react-icons/sl'
import { Link } from 'react-router-dom'
import SwitchImage from '../hooks/useImage'

export default function TopPropertyCard({
  desktopImage,
  mobileImage,
  title,
  price,
  content,
  location,
  link,
}) {
  return (
    <Link
      to={link}
      className='flex flex-col items-start justify-start shadow-md shadow-zinc-800 h-[360px] w-full hover:opacity-80 transition-all duration-300 bg-white'
    >
      <div className='relative w-full h-full'>
        <SwitchImage
          desktop={desktopImage}
          mobile={mobileImage}
        />
        <div className='absolute left-0 top-4 bg-gradient-to-r from-red-600 to-red-500 py-2 px-4'>
          <h1 className='text-white font-bold'>TOP</h1>
        </div>
        <div className='p-4 w-full bg-black/50 backdrop-blur-sm h-1/2 absolute bottom-0 text-white'>
          <h1 className='font-bold drop-shadow-md'>{title}</h1>
          <p className='text-3xl'>{price}</p>
          <p className='mt-1'>{content}</p>
          <div className='flex items-center mt-4'>
            <SlLocationPin />
            <p className='pl-2'>{location}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}
