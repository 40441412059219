import { useState, useEffect } from 'react'
import client from '../client'
import TopPropertyCard from './TopPropertyCard'

export default function TopPropertiesGrid({ id }) {
  const [topPropertiesData, setTopPropertiesData] = useState([])

  useEffect(() => {
    client
      .fetch(
        `*[_type == 'topProperty']{
              mainTitle,
              slug,
              propertyPrice,
              shortDescription,
              mainDesktopImage{asset->{_id,url}},
              mainMobileImage{asset->{_id,url}},
              location,
              beds,
              baths,
              area
            }`
      )
      .then((data) => setTopPropertiesData(data))
      .catch(console.error)
  }, [])

  if (!topPropertiesData) return <div>loading...</div>
  return (
    <section
      id={id}
      className='bg-black py-16 w-full h-full flex flex-col justify-center items-center'
    >
      <div className='w-full px-2 sm:px-12 2xl:px-0 2xl:w-5/6'>
        <h1 className='sm:text-5xl text-3xl drop-shadow-md font-semibold text-white'>
          Check Top Properties
        </h1>
        <div className='h-1 w-20 bg-gradient-to-r from-red-500 to-pink-500 rounded-full'></div>
      </div>
      <div className='w-full px-2 sm:px-12 2xl:px-0 2xl:w-5/6 pt-2 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-4'>
        {topPropertiesData.map((apartment, idx) => (
          <div key={idx}>
            <TopPropertyCard
              link={/topProperties/ + apartment.slug.current}
              desktopImage={apartment.mainDesktopImage.asset.url}
              mobileImage={apartment.mainMobileImage.asset.url}
              title={apartment.mainTitle}
              price={apartment.propertyPrice}
              content={apartment.shortDescription}
              location={apartment.location}
            />
          </div>
        ))}
      </div>
    </section>
  )
}
