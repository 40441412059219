const resources = {
  en: {
    translation: {
      heroVideo: {
        title: 'Hygge Real Estate',
        subTitle: 'The best offers of Hurghada estate market',
      },
      propertiesVideo: {
        title: 'Choose Your Property',
        subTitle: 'Check out the latest income',
      },
      servicesVideo: {
        title: 'Our Services',
        subTitle: 'We provide all types of real estate services',
      },
      topOffersVideo: {
        title: 'Top Offers',
        subTitle: 'Best Offers On Market',
      },
      newsVideo: {
        title: 'Latest News',
        subTitle:
          'Stay up to date with the latest developments in the Hurghada real estate market',
      },
      navLink: {
        about: 'About',
        properties: 'Properties',
        services: 'Services',
        topOffers: 'Top Offers',
        news: 'News',
        contacts: 'Contacts',
      },
      aboutSection: {
        aboutTitle: 'About Our Company',
        aboutContent:
          'Real estate agency Hygge Hurghada Home is engaged in the sale of properties in Hurghada to buyers from all over the world! Our specialists will select for you an apartment, villa, a plot of land or commercial project - which will meet all your requirements. The office works with Russian-speaking and English-speaking buyers, and we also speak Arabic. We offer real estate directly from its owners or developers - which allows us to conduct transactions at prices lower than the average prices on the real estate market. Hygge Hurghada Home is an independent agency that honestly tells about the pros and cons of different properties. Our employees are ready to find for you an apartment or villa for rent for the duration of your vacation so that you have a great time and do not depend on the rules established at the hotel! The selection of real estate depends directly on the purpose of its acquisition - we always take into account the priorities of our clients and their financial capabilities.',
      },
      heroSection: {
        title: 'Meet Olga!',
        content:
          'My acquaintance with the country of the pyramids began back in 2005. Having flown to Hurghada once, I could not forget it. I was imbued with the local flavor, which delighted and repelled at the same time. But despite this and the fact that I traveled a lot around the world, I still always returned to the sunny city. To make it more comfortable to come, I bought my own apartment. I met the neighbors and immersed myself in this atmosphere. And now, as a specialist, engaged in the purchase and sale of real estate, my experience not as a realtor, but as a buyer and owner gives me huge advantages in helping people find what they need, what they like. Not all transactions are smooth. I went through many problems. Not without courts and lawyers. But I became more careful, smarter and learned a lot. New acquaintances, meetings and even possible difficulties only inspire me. I suggest going through the path to acquiring the property of your dreams together, using my skills and personal experience.',
      },
      dealSectionWhite: {
        title: 'How We Work',
        content:
          'Hygge Hurghada Home is a team of qualified realtors and managers, lawyers specializing in the Egyptian real estate market. Our specialists primarily pay close attention to popular tourist areas, have experience and know the market well. The work of our real estate agency as organized as follows: We collect all current offers for the sale or purchase of real estate in Hurghada, Sahl Hasheesh, Soma Bay, El Gouna, New Cairo. At the same time, all ads appear in our real estate catalog very quickly. Many real estate agencies post in the databases those properties that, for various reasons, did not find demand on the domestic market.',
      },
      dealSectionBlack: {
        title: 'Advantages of Working With Us',
        content:
          'However, we took a different path. We provide clients with access to the latest ads, to those that are directly published by homeowners or developers. All offers are published and posted in several languages. This means that the language barrier will not be an obstacle for you. On our portal you can find many offers for any category of real estate, from elite to those that are sold at an affordable price. At the same time, our real estate agency does not charge clients for the services provided. We work differently. Basically, our partners pay us.',
      },
      contactSection: {
        title: 'Get In Touch With Us',
        content:
          'When contacting representatives of our agency in Moscow, you will immediately see competitive advantages in Rostov: a fairly large real estate database that is updated on a regular basis; offers directly from owners or developers; no problems with language barriers; the latest and hottest offers with big discounts; consulting, legal and organizational support during the real estate purchase transaction; no commissions or payments from the client. Access to the database is provided free of charge, as are our services for selecting and conducting purchase transactions, which are absolutely free for you. It is profitable to cooperate with us. And you have the opportunity to see for yourself.',
      },
      footer: {
        title: 'Hygge Hurghada Home',
        content:
          'Hygge Hurghada Home offerrs you best real estate services at Red Sea Area',
      },
      services: {
        selection: {
          title: 'Real Estate Selection',
          content:
            'According to your wishes, we will select for you real estate in Egypt, an apartment or a villa in Hurghada, Makadi, Soma Bay, Sahl Hasheesh, El Gouna. We offer real estate that is being built by our company, our partner developer companies, and also objects of the secondary real estate market in Egypt. We offer payment installments during construction',
        },
        reservation: {
          title: 'Reserve Property',
          content:
            'The situation on the Egyptian real estate market is very dynamic. Buyers of real estate in Egypt are numerous and always try to buy the best properties in terms of location and price. In order to have time to buy the option you like, we reserve properties for our clients for up to 7 days. Without prepayment and deposit. The price remains fixed, as at the time of reservation',
        },
        study: {
          title: 'Study Tour',
          content:
            'Although many of our clients buy properties without viewing, there are still those who want to see and evaluate them on site. To view and select real estate, we organize a familiarization tour. We will meet you and show you all the real estate properties you are interested in, introduce you to life in the country and answer any questions you may have',
        },
        legal: {
          title: 'Legal Support',
          content:
            'We will help you sort out all the contracts, acts, certificates and other documents. We will translate the purchase and sale agreement from Arabic into Russian. We will give you professional advice and will do this until you receive the certificate of ownership. Our company employs two professional lawyers who will give an independent assessment of your property',
        },
        repair: {
          title: 'Repair & Finishing',
          content:
            'We will help you sort out all the contracts, acts, certificates and other documents. We will translate the purchase and sale agreement from Arabic into Russian. We will give you professional advice and will do this until you receive the certificate of ownership. Our company employs two professional lawyers who will give an independent assessment of your property',
        },
        construction: {
          title: 'Construction',
          content:
            'The implementation of this service begins with a discussion of the client wishes and requirements, taking into account which an architectural project is prepared and land plot options are provided. The construction period is 4.5 months. The facility is delivered "turnkey". At the client request, a swimming pool is installed, and landscape design work is carried out. We will help make your dream of an ideal villa come true!',
        },
        afterSale: {
          title: 'After-Sales Service',
          content:
            'Support in registration of all types of insurance. When buying an apartment for profit - renting and subsequent resale',
        },
      },
    },
  },
  ru: {
    translation: {
      heroVideo: {
        title: 'Недвижимость Хургады',
        subTitle: 'Лучшие предложения на рынке недвижимости Хургады',
      },
      propertiesVideo: {
        title: 'Выберите Ваш Объект',
        subTitle: 'Изучите самые свежие предложения',
      },
      servicesVideo: {
        title: 'Наши Услуги',
        subTitle: 'Предоставим все виды услуг по недвижимости',
      },
      topOffersVideo: {
        title: 'Топ предложения',
        subTitle: 'Лучшие предложения на рынке',
      },
      newsVideo: {
        title: 'Последние Новости',
        subTitle:
          'Будье в курсе последних событий на рынке недвижимости Хургады',
      },
      navLink: {
        about: 'О нас',
        properties: 'Объекты',
        services: 'Услуги',
        topOffers: 'Топ Предложения',
        news: 'Новости',
        contacts: 'Контакты',
      },
      aboutSection: {
        aboutTitle: 'О нашей компании',
        aboutContent:
          'Агентство недвижимости Hygge Hurghada Home занимается продажей объектов в Хургаде покупателям со всего мира! Наши специалисты подберут для вас квартиру, виллу, земельный участок или коммерческий проект - который будет соответствовать всем вашим пожеланиям. Офис работает с русскоязычными и англоязычными покупателями а также мы говорим на арабском. Мы предлагаем недвижимость напрямую от ее владельцев или застройщиков - что позволяет проводить сделки по ценам ниже чем средние цены на рынке недвижимости. Hygge Hurghada Home - независимое агентство, где честно рассказывают о плюсах и минусах разных объектов. Наши сотрудники готовы найти для вас квартиру или виллу в аренду на время отдыха чтобы вы прекрасно провели время и не зависели от правил, установленных в отеле! Подбор недвижимости зависит напрямую от цели ее приобретения - мы всегда учитываем приоритеты наших клиентов и их финансовые возможности.',
      },
      heroSection: {
        title: 'Знакомтесь с Ольгой!',
        content:
          'Мое знакомство со страной пирамид началось еще в 2005 году. Прилетев однажды в Хургаду, я уже не смогла ее забыть. Я прониклась местным колоритом, который восхищал и отталкивал одновременно. Но, несмотря на это и то, что я много путешествовала по миру, все же всегда возвращалась в солнечный город. Чтобы приезжать было комфортнее, я приобрела собственную квартиру. Познакомилась с соседями и окунулась в эту атмосферу. И сейчас как специалист, занимаясь покупкой и продажей недвижимости, мой опыт не риэлтора, а покупателя и собственника дает мне огромные преимущества помогать людям найти то, что им нужно, то, что им по душе. Не все сделки бывают гладкими. Я прошла через множество проблем. Не обошлось без судов и адвокатов. Зато я стала осторожнее, умнее и многое узнала. Новые знакомства, встречи и даже возможные трудности меня только вдохновляют. Предлагаю пройти путь к преобретению недвижимости вашей мечты вместе, используя мои навыки и личный опыт.',
      },
      dealSectionWhite: {
        title: 'Как Мы Работаем',
        content:
          'Hygge Hurghada Home представляет собой команду квалифицированных риелторов и менеджеров, юристов специализирующуюся на рынке недвижимости Египта.Наши специалисты в первую очередь уделяют самое пристальное внимание популярным туристическим районам , имеют опыт и хорошо  знают рынок. Работа нашего агентства недвижимости организована следующим образом: Мы собираем все актуальные предложения о продаже или покупке недвижимости в Хургаде, Сахль Хашиш, Сома Бэй , Эль Гуне , Новом Каире. При этом в нашем каталоге недвижимости все объявления появляются очень оперативно. Многие агентства недвижимости выкладывают в базы те объекты, которые не нашли по разным причинам спроса на внутреннем рынке.',
      },
      dealSectionBlack: {
        title: 'Преимущества Работы с Нами',
        content:
          'Однако мы пошли другим путём. Мы предоставляем клиентам доступ к самым свежим объявлениям, к тем, которые напрямую публикуют владельцы жилья или застройщики. Все предложения публикуются и выкладываются на нескольких  языках. Это значит, что языковой барьер не станет для вас препятствием. На нашем портале можно найти много предложений по любой категории недвижимости, начиная от элитной и заканчивая той, которая реализуется по доступной цене. При этом наше агентство недвижимости не берёт с клиентов оплату за оказание услуг. Мы работаем иначе. В основном нам платят наши партнёры.',
      },
      contactSection: {
        title: 'Свяжитесь с Нами',
        content:
          'Обращаясь к представителям нашего агентства в Москве, в Ростове вы сразу увидите конкурентные преимущества: достаточно большая база недвижимости обновляющаяся на постоянной основе; предложения напрямую от владельцев или застройщиков; отсутствие проблем с языковым барьером; самые свежие и горящие предложения с большим дисконтом; консультационная, правовая и организационная поддержка при проведении сделки по покупке недвижимости; отсутствие комиссий и выплат со стороны клиента. Доступ к базе предоставляется бесплатно, как и совершенно бесплатны для вас наши услуги по подбору и проведению сделок покупки. С нами выгодно сотрудничать. И у вас есть возможность в этом убедиться.',
      },
      footer: {
        title: 'Hygge Hurghada Home',
        content:
          'Hygge Hurghada Home предлагает вам лучшие услуги в сфере недвижимости в Хургаде',
      },
      services: {
        selection: {
          title: 'Подбор Недвижимости',
          content:
            'По Вашим пожеланиям мы подберем Вам недвижимость в Египте, квартиру или виллу в Хургаде, Макади, Сома Бэй, Сахль Гашиш,Эль Гуна. Мы предлагаем недвижимость которую строит наша компания, наши партнёры компании застройщики, и так же объекты вторичного рынка недвижимости Египта. Предлагаем рассрочку оплаты на время строительства',
        },
        reservation: {
          title: 'Зарезервировать Объект',
          content:
            'Ситуация на рынке недвижимости Египта очень динамична. Покупатели недвижимости в Египте многочисленны и всегда стараются покупать самые лучшие по расположению и цене объекты. Что бы успеть купить понравившийся вариант, мы резервируем для наших клиентов объекты сроком до 7 дней. Без предоплаты и залогового взноса. Цена остаётся фиксированной, как и на момент резервации',
        },
        study: {
          title: 'Ознакомительная Поездка',
          content:
            'Хотя многие наши клиенты покупают объекты без осмотра, остаются те, кто хочет на месте посмотреть и оценить. Для просмотра и выбора недвижимости мы организуем ознакомительный тур. Мы встретим Вас и покажем все интересующие Вас объекты недвижимости, познакомим с жизнью в стране и ответим на интересующие Вас вопросы',
        },
        legal: {
          title: 'Юридическое Сопровождение Сделки',
          content:
            'Мы поможем Вам разобраться со всеми договорами, актами, свидетельствами и прочими документами. Переведем договор купли-продажи с арабского на русский язык. Дадим Вам профессиональную консультацию и будем делать это до тех пор, пока Вы не получите - свидетельство о собственности. В штате нашей компании работают два профессиональных юриста, которые дадут независимую оценку Вашей недвижимости',
        },
        repair: {
          title: 'Ремонт и Отделка',
          content:
            'Мы поможем решить все вопросы, связанные с ремонтом. Наша компания предлагает полный комплекс работ по ремонту квартир, вилл - под ключ. Выбор отделки по каталогам. С нами Вам будет гораздо легче найти подходящую мебель в Хургаде, Египте, причём вам не обязательно лично присутствовать при проведении работ. Если же, Вы желаете собственноручно заняться поисками мебели в Хургаде, Вам также пригодятся наши услуги, мы дадим Вам дельные советы и поможем не растеряться в многообразии представленных вариантов',
        },
        construction: {
          title: 'Строительство',
          content:
            'Реализация данной услуги начинается с обсуждения пожеланий и требований клиента, с учетом которых готовится архитектурный проект и предоставляются варианты земельных участков. Срок строительства 4,5 месяца. Объект сдается «под ключ». По желанию клиента устанавливается бассейн, проводятся работы по ландшафтному дизайну. Мы поможем осуществить Вашу мечту о идеальной вилле!',
        },
        afterSale: {
          title: 'Послепродажный Сервис',
          content:
            'Поддержка при оформлении всех видов страхования. При покупке квартиры с целью получения прибыли - сдача в аренду и дальнейшая перепродажа',
        },
      },
    },
  },
}

export default resources
