import SwitchVideo from '../hooks/useVideo'
import { IoIosArrowDown } from 'react-icons/io'

export default function Video({ mobile, desktop, title, subTitle, link }) {
  return (
    <section className='relative w-full h-screen md:h-[600px] bg-black'>
      <SwitchVideo
        mobile={mobile}
        desktop={desktop}
      />
      <div className='w-full xl:w-1/2 mx-auto absolute inset-0 flex flex-col justify-center items-center gap-4 text-white text-center'>
        <h1 className='text-4xl sm:text-6xl md:text-7xl xl:text-8xl  px-4 drop-shadow-md font-bold'>
          {title}
        </h1>
        <p className='text-md md:text-2xl w-3/4 drop-shadow-md'>{subTitle}</p>
      </div>
      <a
        href={link}
        className='absolute md:hidden bottom-12 left-1/2 text-white transform -translate-x-1/2'
      >
        <IoIosArrowDown size={48} />
      </a>
    </section>
  )
}
