export default function SwitchImage({ mobile, desktop }) {
  const isDesktopDisabledVideo = window.matchMedia('(max-width:768px)').matches
  return (
    <>
      {isDesktopDisabledVideo ? (
        <img
          className='object-cover w-full h-full'
          src={mobile}
          alt='mobile_img'
          loading='lazy'
        />
      ) : (
        <img
          className='object-cover w-full h-full'
          src={desktop}
          alt='desktop_img'
          loading='eager'
        />
      )}
    </>
  )
}
