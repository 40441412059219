import ContactForm from './ContactForm'
import { useTranslation } from 'react-i18next'

export default function ContactBanner() {
  const { t } = useTranslation()
  return (
    <section className='flex justify-center py-12 sm:py-24'>
      <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col xl:flex-row gap-8 2xl:gap-16'>
        <div className='w-full xl:w-3/5 2xl:w-2/3'>
          <div className='w-full lg:mb-0'>
            <h1 className='sm:text-5xl text-3xl  drop-shadow-md font-semibold sm:mb-2 text-gray-700'>
              {t('contactSection.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full'></div>
          </div>
          <h1 className='mt-2 sm:mt-4 text-xl text-gray-600 drop-shadow-md font-normal'>
            {t('contactSection.content')}
          </h1>
        </div>
        <div className='w-full 2xl:w-1/3'>
          <ContactForm />
        </div>
      </div>
    </section>
  )
}
