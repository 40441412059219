import { Route, Routes } from 'react-router-dom'
import HeroBanner from './components/HeroBanner'
import Properties from './pages/Properties'
import News from './pages/News'
import DealBannerBlack from './components/DealBannerBlack'
import DealBannerWhite from './components/DealBannerWhite'
import ContactBanner from './components/ContactBanner'
import Contact from './pages/Contact'
import Services from './pages/Services'
import TopPropertiesGrid from './components/TopPropertiesGrid'
import Blog from './pages/Blog'
import Property from './pages/Property'
import TopProperty from './pages/TopProperty'
import TopProperties from './pages/TopProperties'
import AboutBanner from './components/AboutBanner'
import Video from './components/Video'
import mobile from '../src/assets/videos/heroMobile.mp4'
import desktop from '../src/assets/videos/heroDesktop.mp4'
import { useTranslation } from 'react-i18next'

function App() {
  const { t } = useTranslation()
  return (
    <main>
      <Routes>
        <Route
          path='/'
          element={
            <article>
              <Video
                mobile={mobile}
                desktop={desktop}
                title={t('heroVideo.title')}
                subTitle={t('heroVideo.subTitle')}
                link={'#top'}
              />
              <AboutBanner id={'top'} />
              <HeroBanner />
              <TopPropertiesGrid />
              <DealBannerWhite />
              <DealBannerBlack />
              <ContactBanner />
            </article>
          }
        />
        <Route
          path='/properties'
          element={<Properties />}
        />
        <Route
          path='/properties/:slug'
          element={<Property />}
        />
        <Route
          path='/topProperties'
          element={<TopProperties />}
        />
        <Route
          path='/topProperties/:slug'
          element={<TopProperty />}
        />
        <Route
          path='/news'
          element={<News />}
        />
        <Route
          path='/news/:slug'
          element={<Blog />}
        />
        <Route
          path='/contact'
          element={<Contact />}
        />
        <Route
          path='/services'
          element={<Services />}
        />
      </Routes>
    </main>
  )
}

export default App
