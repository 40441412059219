import PropertiesGrid from '../components/PropertiesGrid'
import Video from '../components/Video'
import mobile from '../assets/videos/propertiesMob.mp4'
import desktop from '../assets/videos/propertiesDesktop.mp4'
import { useTranslation } from 'react-i18next'

export default function Properties() {
  const { t } = useTranslation()
  return (
    <article>
      <Video
        mobile={mobile}
        desktop={desktop}
        title={t('propertiesVideo.title')}
        subTitle={t('propertiesVideo.subTitle')}
        link={'#properties'}
      />
      <PropertiesGrid id={'properties'} />
    </article>
  )
}
