import { useState, useEffect } from 'react'
import client from '../client'
import { Link } from 'react-router-dom'
import PropertyCard from './PropertyCard'

export default function PropertiesGrid({ id }) {
  const [propertiesData, setPropertiesData] = useState([])

  useEffect(() => {
    client
      .fetch(
        `*[_type == 'property']{
              mainTitle,
              slug,
              propertyPrice,
              shortDescription,
              mainDesktopImage{asset->{_id,url}},
              mainMobileImage{asset->{_id,url}},
              location,
              beds,
              baths,
              area
            }`
      )
      .then((data) => setPropertiesData(data))
      .catch(console.error)
  }, [])

  return (
    <section
      id={id}
      className='w-full h-full flex flex-col justify-center items-center'
    >
      <div className='w-full 2xl:w-5/6 px-2 md:px-12 2xl:px-0 pt-16'>
        <h1 className='sm:text-5xl text-3xl drop-shadow-md font-semibold sm:mb-2 text-gray-700'>
          Check Our Properties
        </h1>
        <div className='h-1 w-20 bg-gradient-to-r from-lime-400 to-violet-500 rounded-full'></div>
      </div>
      <div className='w-full px-2 sm:px-12 2xl:px-0 2xl:w-5/6 py-2 sm:py-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-4'>
        {propertiesData.map((property, idx) => (
          <Link
            to={/properties/ + property.slug.current}
            key={idx}
          >
            <PropertyCard
              desktopImage={property.mainDesktopImage.asset.url}
              mobileImage={property.mainMobileImage.asset.url}
              title={property.mainTitle}
              price={property.propertyPrice}
              content={property.shortDescription}
              location={property.location}
              beds={property.beds}
              baths={property.baths}
              area={property.area}
            />
          </Link>
        ))}
      </div>
    </section>
  )
}
