import SwitchVideo from '../hooks/useVideo'
import mobileVideo from '../assets/videos/mobileDealBlack.mp4'
import desktopVideo from '../assets/videos/desktopDealBlack.mp4'
import { useTranslation } from 'react-i18next'

export default function DealBannerBlack() {
  const { t } = useTranslation()
  return (
    <section className='flex justify-center py-2 sm:py-12 xl:py-24 bg-black'>
      <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col xl:flex-row gap-8 2xl:gap-16'>
        <div className='w-full md:w-2/3  2xl:w-2/5 shadow-md'>
          <SwitchVideo
            mobile={mobileVideo}
            desktop={desktopVideo}
          />
        </div>
        <div className='w-full xl:w-3/5 2xl:w-3/5'>
          <div className='w-full lg:mb-0'>
            <h1 className='sm:text-5xl text-3xl text-white drop-shadow-md font-semibold sm:mb-2 flex items-center'>
              {t('dealSectionBlack.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r  from-green-500 to-blue-600 rounded-full'></div>
          </div>
          <h1 className='mt-2 sm:mt-4 text-xl text-white drop-shadow-md font-normal'>
            {t('dealSectionBlack.content')}
          </h1>
        </div>
      </div>
    </section>
  )
}
