import { SlLocationPin } from 'react-icons/sl'
import { IoBedOutline } from 'react-icons/io5'
import { LiaBathSolid } from 'react-icons/lia'
import { BiArea } from 'react-icons/bi'
import SwitchImage from '../hooks/useImage'

export default function PropertyCard({
  desktopImage,
  mobileImage,
  title,
  price,
  content,
  location,
  beds,
  baths,
  area,
}) {
  return (
    <div className='flex flex-col items-start justify-start shadow-lg shadow-gray-300 h-[500px] w-full hover:opacity-80 transition-all duration-300'>
      <div className='w-full h-1/2'>
        <SwitchImage
          desktop={desktopImage}
          mobile={mobileImage}
        />
      </div>
      <div className='p-4 w-full h-1/2'>
        <h1 className='font-bold drop-shadow-md'>{title}</h1>
        <p className='text-3xl'>{price}</p>
        <p className='mt-2'>{content}</p>
        <div className='flex items-center mt-4'>
          <SlLocationPin />
          <p className='pl-2'>{location}</p>
        </div>
        <div className='mt-2 flex gap-4 items-center'>
          <div className='flex items-center gap-2'>
            <IoBedOutline />
            <p className='font-bold drop-shadow-md'>{beds}</p>
          </div>
          <div className='flex items-center gap-2'>
            <LiaBathSolid />
            <p className='font-bold drop-shadow-md'>{baths}</p>
          </div>
          <div className='flex items-center gap-2'>
            <BiArea />
            <p className='font-bold drop-shadow-md'>
              {area}
              <span className='pl-[2px]'>sq.m.</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
