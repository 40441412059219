import NewsGrid from '../components/NewsGrid'
import Video from '../components/Video'
import mobile from '../assets/videos/newsMob.mp4'
import desktop from '../assets/videos/newsDesktop.mp4'
import { useTranslation } from 'react-i18next'

export default function News() {
  const { t } = useTranslation()
  return (
    <article>
      <section>
        <Video
          mobile={mobile}
          desktop={desktop}
          title={t('newsVideo.title')}
          subTitle={t('newsVideo.subTitle')}
          link={'#newsGrid'}
        />
      </section>
      <NewsGrid id='newsGrid' />
    </article>
  )
}
