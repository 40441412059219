import { Link } from 'react-router-dom'

export default function ContactForm() {
  return (
    <div className='shadow-lg bg-gray-200 p-8 flex flex-col w-full relative z-10'>
      <div className='relative mb-4'>
        <label className='leading-7 text-sm drop-shadow-sm text-gray-800 font-semibold'>
          Email
        </label>
        <input
          type='email'
          id='email'
          name='email'
          className='w-full bg-gray-200 rounded border border-gray-400  focus:ring-1 focus:ring-gray-600 outline-none text-gray-800 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out'
        />
      </div>
      <div className='relative mb-4'>
        <label className='leading-7 drop-shadow-sm text-sm text-gray-800 font-semibold'>
          Message
        </label>
        <textarea
          id='message'
          name='message'
          className='w-full bg-gray-200 rounded border border-gray-400 focus:ring-1 focus:ring-gray-600 h-32 outline-none text-gray-800 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out'
        ></textarea>
      </div>
      <Link
        to='/'
        className='inline-flex items-center justify-center px-5 py-3 text-base font-semibold text-center text-gray-800 border border-gray-400 rounded-md hover:bg-gray-300 hover:text-gray-900  
          transition-all'
      >
        <button>Send Your Message</button>
      </Link>
    </div>
  )
}
