export default function SwitchVideo({ mobile, desktop }) {
  const isDesktopDisabledVideo = window.matchMedia('(max-width:768px)').matches

  return (
    <>
      {isDesktopDisabledVideo ? (
        <video
          className='bg-black w-full h-full object-cover pointer-events-none select-none'
          loop
          autoPlay
          muted
          playsInline
          controls={false}
          preload='auto'
          width='100%'
          height='100%'
        >
          <source src={mobile} />
        </video>
      ) : (
        <video
          className='bg-black w-full h-full object-cover  pointer-events-none select-none aspect-video disable-video'
          loop
          autoPlay
          muted
          playsInline
          controls={false}
          preload='auto'
          width='100%'
          height='100%'
        >
          <source src={desktop} />
        </video>
      )}
    </>
  )
}
