import SwitchImage from '../hooks/useImage'
import mobileImage from '../assets/images/heroMobile.jpg'
import desktopImage from '../assets/images/heroDesktop.jpg'
import { useTranslation } from 'react-i18next'

export default function HeroBanner() {
  const { t } = useTranslation()
  return (
    <section className='flex justify-center xl:py-24'>
      <div className='px-2 sm:px-0 w-full sm:w-5/6 flex flex-col xl:flex-row gap-8 2xl:gap-16'>
        <div className='w-full md:w-2/3 2xl:w-1/3 shadow-md'>
          <SwitchImage
            mobile={mobileImage}
            desktop={desktopImage}
          />
        </div>
        <div className='w-full xl:w-3/5 2xl:w-2/3'>
          <div className='w-full lg:mb-0'>
            <h1 className='sm:text-5xl text-2xl  drop-shadow-md font-semibold sm:mb-2 text-gray-700'>
              {t('heroSection.title')}
            </h1>
            <div className='h-1 w-20 bg-gradient-to-r  from-green-500 to-blue-600 rounded-full'></div>
          </div>
          <h1 className='mt-2 sm:mt-4 text-lg sm:text-xl text-gray-600 drop-shadow-md font-normal'>
            {t('heroSection.content')}
          </h1>
        </div>
      </div>
    </section>
  )
}
