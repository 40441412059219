import NewsCard from './NewsCard'
import client from '../client'
import { useEffect, useState } from 'react'

export default function NewsGrid({ id }) {
  const [blogData, setBlogData] = useState([])

  useEffect(() => {
    client
      .fetch(
        `*[_type == 'blog']{
      blogTitle,
      shortDescription,
      slug,
      authorAvatar{asset->{_id,url}},
      authorName,
      firstDesktopImage{asset->{_id,url}},
      firstMobileImage{asset->{_id,url}}
      }`
      )
      .then((data) => setBlogData(data))
      .catch(console.error)
  }, [])

  return (
    <section
      id={id}
      className='w-full h-full flex flex-col justify-center items-center'
    >
      <div className='w-full 2xl:w-5/6 px-2 md:px-12 2xl:px-0 pt-12'>
        <h1 className='sm:text-5xl text-3xl drop-shadow-md font-semibold sm:mb-2'>
          Our Blog Posts
        </h1>
        <div className='h-1 w-20 bg-gradient-to-r from-yellow-400 to-orange-600 rounded-full'></div>
      </div>
      <div className='w-full 2xl:w-5/6 px-2 md:px-12 2xl:px-0 py-2 md:pb-12 grid grid-cols-1 xl:grid-cols-2 gap-2 md:gap-4'>
        {blogData.map((blog, idx) => (
          <div key={idx}>
            <NewsCard
              link={/news/ + blog.slug.current}
              title={blog.blogTitle}
              description={blog.shortDescription}
              desktopImage={blog.firstDesktopImage.asset.url}
              mobileImage={blog.firstMobileImage.asset.url}
              avatar={blog.authorAvatar.asset.url}
              author={blog.authorName}
            />
          </div>
        ))}
      </div>
    </section>
  )
}
